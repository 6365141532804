import { environment } from "environments/environment";

const API_URL = environment.backendURL;

export const LOGIN_ROUTE = `${API_URL}/user/login`;
export const USER_ROUTE = `${API_URL}/user`;
export const CONTACT_ROUTE = `${API_URL}/contact`;
export const SETTINGS_ROUTE = `${API_URL}/settings`;
export const TOKEN_VALIDATION_ROUTE = `${API_URL}/user/validateToken`;
export const SHIPMENT_ROUTE = `${API_URL}/shipment`;
export const PARCEL_CATEGORY_ROUTE = `${API_URL}/parcel-categories`;
export const PARCEL_EBAY_ROUTE = `${API_URL}/ebay-order`;
export const PARCEL_LABEL_ROUTE = `${API_URL}/label`;
export const GLS_ROUTE = `${API_URL}/gls`;
export const AMAZON_ROUTE = `${API_URL}/amazon`;
export const WEATHER_ROUTE = `${API_URL}/weather`;
export const FILE_TEMPLATE_ROUTE = `${API_URL}/template/file`;
export const FILE_IMPORT_ROUTE = `${API_URL}/import/file`;
export const FILE_EXPORT_ROUTE = `${API_URL}/export/file`;
export const CONSENT_ROUTE = `${API_URL}/consent`;
export const TRACKING_ROUTE = `${API_URL}/tracking`;
export const WOOCOMMERCE_ROUTE = `${API_URL}/woocommerce`;
export const SHOP_DEFINITION_ROUTE = `${API_URL}/shop-definition`;
export const SHOPWARE_ROUTE = `${API_URL}/shopware`;
export const MARKETPLACE_ROUTE = `${API_URL}/marketplace-definition`;
export const BASIC_ROUTE = `${API_URL}/`;
