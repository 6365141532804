import {Component, Input, OnInit} from '@angular/core';
import {MarketplaceDefinitionService, MarketplaceType} from "../../services/marketplace/marketplace-definition.service";
import {NbDialogRef, NbDialogService} from "@nebular/theme";
import {ConfirmComponent} from "../confirm/confirm.component";
import {GenericResponse} from "../../responses/GenericResponse";
import {MarketplaceDefinition} from "../../models/MarketplaceDefinition";
import {ToastService} from "../../services/toast/toast.service";

@Component({
    selector: 'ngx-marketplace-popup',
    templateUrl: './marketplace-popup.component.html',
    styleUrls: ['./marketplace-popup.component.scss'],
})
export class MarketplacePopupComponent implements OnInit {

    @Input() public market: MarketplaceDefinition = new MarketplaceDefinition();
    @Input() isEdit: boolean = false;
    public types: MarketplaceType[] = this.marketplaceService.marketplaceTypes;

    constructor(
        protected dialogRef: NbDialogRef<any>,
        private toastService: ToastService,
        private modalController: NbDialogService,
        private marketplaceService: MarketplaceDefinitionService
    ) {
    }

    ngOnInit(): void {
        //
    }

    public close(): void{
        this.dialogRef.close();
    }

    public save(): void{
        if(!this.isEbay() && (
            this.market.marketplaceType == undefined || this.market.marketplaceType.length < 1 ||
            this.market.marketplaceName == undefined || this.market.marketplaceName.length < 1 ||
            this.market.marketplaceUrl == undefined || this.market.marketplaceUrl.length < 1 ||
            this.market.marketplaceConnectionKey == undefined || this.market.marketplaceConnectionKey.length < 1 ||
            this.market.marketplaceConnectionSecret == undefined || this.market.marketplaceConnectionSecret.length < 1)) {
            this.toastService.showToast(this.toastService.TOAST_STATUS.warning, this.toastService.MISSING_ANY_MANDATORY_FIELD, 10000);
            return;
        }else if(this.isEbay() &&
            (this.market.marketplaceType == undefined || this.market.marketplaceType.length < 1 ||
            this.market.marketplaceName == undefined || this.market.marketplaceName.length < 1)){
            this.toastService.showToast(this.toastService.TOAST_STATUS.warning, this.toastService.MISSING_ANY_MANDATORY_FIELD, 10000);
            return;
        }
        if(this.isEdit){
            this.marketplaceService.update(this.market.marketplaceDefinitionId, this.market).subscribe({
                error: err => {
                    this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.API_REGISTER_FAILED, 10000);
                },
                next: () => {
                    this.toastService.showToast(this.toastService.TOAST_STATUS.success, this.toastService.API_REGISTER_SUCCESS, 10000);
                    this.dialogRef.close({reload: true});
                },
            });
        }else{
            this.marketplaceService.create(this.market).subscribe({
                error: err => {
                    this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.API_REGISTER_FAILED, 10000);
                },
                next: (res: GenericResponse<MarketplaceDefinition>) => {
                    if(res.status != undefined && res.status == 200 && res.body != undefined){
                        this.toastService.showToast(this.toastService.TOAST_STATUS.success, this.toastService.API_REGISTER_SUCCESS, 10000);
                        this.dialogRef.close({reload: true, type: 'ebay', id: res.body.marketplaceDefinitionId});
                    }
                },
            });
        }
    }

    public delete(): void{
        const modal = this.modalController.open(ConfirmComponent);
        modal.onClose.subscribe({
            next: async data => {
                if(data != undefined){
                    if(data.delete){
                        let error = false;
                        await this.marketplaceService.delete(this.market.marketplaceDefinitionId).toPromise()
                            .catch(err=> {
                                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.API_DELETE_FAILED, 10000, err);
                                error = true;
                            }).then((res: GenericResponse<string>) => {
                                if(res != undefined && res.status != undefined && (res.status == 200 || (typeof res.status == 'string' && res.status.toLowerCase() == 'ok'))){
                                    this.dialogRef.close({reload: true});
                                }
                            });

                    }
                }
            },
        });
    }

    public isEbay(): boolean{
        if(this.market != undefined && this.market.marketplaceType != undefined){
            return this.market.marketplaceType.indexOf('ebay') > -1;
        }
        return false;
    }

}
