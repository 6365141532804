<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a *ngIf="!demo" href="#" (click)="navigateHome()">
      <img 
        src="assets/images/logos/mr-shipment-new-logo.png"
        class="image logo-image"
      />
    </a>
    <a *ngIf="demo" href="#" (click)="navigateHome()">
      <img
              src="assets/images/logos/mr-shipment-demo-logo.png"
              class="image"
      />
    </a>
    <div *ngIf="loadingWeatherFailed" class="weatherContainer">
      <p style="color: red; white-space: nowrap">Die Wetterdaten konnten nicht geladen werden.</p>
      <p style="color: red; white-space: nowrap">Pr&uuml;fen Sie Ihre Angaben. <i class="fas fa-cog failBtn" (click)="openWeatherSettings()"></i></p>
    </div>

    <div *ngIf="!loadingWeatherFailed" [nbSpinner]="loadingWeather" class="weatherContainer">
      <img *ngIf="!loadingWeather && weatherIcon != undefined && weatherIcon.length > 0" src="https://openweathermap.org/img/wn/{{weatherIcon}}.png" alt="">
      <span *ngIf="!loadingWeather && temperature != undefined && temperature.length > 0" class="temperature">{{temperature | number:'1.0-0'}} &deg;C</span>
      <i *ngIf="!loadingWeather" class="fas fa-cog btn" (click)="openWeatherSettings()"></i>
    </div>
    <span *ngIf="demo" style="margin-right: 10px; padding: 5px; font-weight: bold; white-space: nowrap; border: 2px solid red;">Sie befinden sich im Demo-System. Alle angezeigten Preise sind Beispielpreise.</span>
  </div>
  <!--
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
  -->
</div>
<div class="header-container" *ngIf="isChristmasHustle()">
  <div class="global-warning">
    <span>Aufgrund erhöhtem Paketaufkommens, kann es zu Verzögerungen bei der Lieferung kommen.</span>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">

    <!--
    <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action>
    -->
    <!--
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
    -->
      <!--
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture">
      </nb-user>
      -->
    <!--
      <nb-icon icon="person"></nb-icon>
    </nb-action>
    -->
    <nb-action *ngIf="warnShipments != undefined && warnShipments.length > 0" class="finalReportWarn" (click)="openFinalReportWarn()">
      Sendungen ins Versandarchiv verschieben!
      <span class="arrow">
        &rarr;
      </span>
    </nb-action>
    <nb-action class="packing-info" (click)="openPackingInfo()">
      So verpacken Sie korrekt!
    </nb-action>
    <nb-action class="manual" (click)="openManual()">
      <nb-icon class="fas fa-question"></nb-icon>
    </nb-action>
  </nb-actions>
</div>
